html.force-mobile-layout {
  .container, .container-fluid {
    width: 100%;
  }

  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: none;
    width: auto;
  }

  .col-sm-push-1, .col-sm-push-2, .col-sm-push-3, .col-sm-push-4, .col-sm-push-5, .col-sm-push-6, .col-sm-push-7, .col-sm-push-8, .col-sm-push-9, .col-sm-push-10, .col-sm-push-11, .col-sm-push-12,
  .col-md-push-1, .col-md-push-2, .col-md-push-3, .col-md-push-4, .col-md-push-5, .col-md-push-6, .col-md-push-7, .col-md-push-8, .col-md-push-9, .col-md-push-10, .col-md-push-11, .col-md-push-12,
  .col-lg-push-1, .col-lg-push-2, .col-lg-push-3, .col-lg-push-4, .col-lg-push-5, .col-lg-push-6, .col-lg-push-7, .col-lg-push-8, .col-lg-push-9, .col-lg-push-10, .col-lg-push-11, .col-lg-push-12,
  .col-sm-pull-1, .col-sm-pull-2, .col-sm-pull-3, .col-sm-pull-4, .col-sm-pull-5, .col-sm-pull-6, .col-sm-pull-7, .col-sm-pull-8, .col-sm-pull-9, .col-sm-pull-10, .col-sm-pull-11, .col-sm-pull-12,
  .col-md-pull-1, .col-md-pull-2, .col-md-pull-3, .col-md-pull-4, .col-md-pull-5, .col-md-pull-6, .col-md-pull-7, .col-md-pull-8, .col-md-pull-9, .col-md-pull-10, .col-md-pull-11, .col-md-pull-12,
  .col-lg-pull-1, .col-lg-pull-2, .col-lg-pull-3, .col-lg-pull-4, .col-lg-pull-5, .col-lg-pull-6, .col-lg-pull-7, .col-lg-pull-8, .col-lg-pull-9, .col-lg-pull-10, .col-lg-pull-11, .col-lg-pull-12 {
    left: auto;
    right: auto;
  }

  .col-sm-offset-1, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12,
  .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12,
  .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12 {
    margin-left: auto;
  }

  .navbar {
    border-radius: 0;
  }

  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;

    &.caret {
      display: inline-block;
    }
  }

  .navbar-collapse {
    display: none !important;
    padding-right: $navbar-padding-horizontal;
    padding-left: $navbar-padding-horizontal;
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.1);

    &.in, &.collapsing {
      display: block !important;
    }

    .navbar-fixed-top &, .navbar-fixed-bottom & {
      max-height: $navbar-collapse-max-height;
    }
  }

  .navbar-nav {
    float: none;
    margin: ($navbar-padding-vertical / 2) (-$navbar-padding-horizontal);

    & > li {
      float: none;
    }

    & > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;

      & > li > a, .dropdown-header {
        padding: 5px 15px 5px 25px;
      }

      & > li > a {
        line-height: $line-height-computed;

        &:hover, &:focus {
          background-image: none;
        }
      }
    }
  }

  .navbar-left {
    float: none !important;
  }

  .navbar-right {
    float: none !important;
  }

  .navbar-form {
    width: 100%;
    margin-left: -$navbar-padding-horizontal;
    margin-right: -$navbar-padding-horizontal;
    padding: 10px $navbar-padding-horizontal;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    $shadow: inset 0 1px 0 rgba(255,255,255,.1), 0 1px 0 rgba(255,255,255,.1);
    @include box-shadow($shadow);
  }

  .navbar-text {
    @include navbar-vertical-align($line-height-computed);
    float: none;
  }

  .container, .container-fluid {
    width: 100%;

    > .navbar-header, > .navbar-collapse {
      margin-right: -$navbar-padding-horizontal;
      margin-left:  -$navbar-padding-horizontal;
    }
  }
}
