@import 'variables';
@import 'bootstrap';
@import 'forceMobileLayout.scss';
// #############################################################################
// Default Elements
// #############################################################################
// Set some defaults to get the page to be properly rendered using rem units.
html,
body {
  font-size: 14px;
  div,
  p,
  label,
  li,
  select,
  span {
    font-size: 1rem;
    // Leave this out as it breaks Fluid UI -- line-height: 1.5rem;
  }
}

body {
  top: 0 !important;
  min-width: 300px;
  font-family: $typeface-default;
  @media (min-width: $screen-md-min) {
    html:not(.force-mobile-layout) & {
      border-top: 6rem solid transparent;
    }
  }
}

.alert-warning {
  color: #684d1e;
}

pre {
  font-size: 0.9rem;
}
h1 {
    font-family: 'Roboto Slab', 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 2.35rem;
    line-height: 3rem;
}
h2 {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2rem;
}
h3 {
    font-size: 1.4rem;
    line-height: 2rem;
}
h4 {
    font-size: 1.125rem; 
    line-height: 1.5rem;
}


// adjust the position of a fixed-top navbar based on the different navbar admin
// menu states.
body.navbar-administration.navbar-is-fixed-top {
   border-top: 6rem solid transparent; // this is a workaround to adjust for the
                                       // extra padding-top that the navbar javascript applies
  .navbar-fixed-top {
    top: 2rem;
  }
}

body.navbar-administration.navbar-is-fixed-top.navbar-horizontal, body.navbar-administration.navbar-is-fixed-top.navbar-vertical {
  .navbar-fixed-top {
    top: 0;
  }
}

body.navbar-administration.navbar-is-fixed-top.navbar-horizontal.navbar-tray-open {
  .navbar-fixed-top {
    top: 3.2rem;
  }
}

// make some adjustments when admin menu is in use

body.admin-menu {
  .flc-prefsEditor-separatedPanel {
    top: 29px;
  }
}

@media (min-width: 353px) and (max-width: 480px) {
  .fl-prefsEditor-separatedPanel .fl-panelBar .fl-prefsEditor-buttons {
    height: auto!important;
  }
}


// #############################################################################
// Bootstrap and Drupal Bootstrap Overrides
// #############################################################################
.navbar-fixed-top {
  z-index: 10000000;
  @media (min-width: $screen-sm-min) {
    position: relative; 
  }
  position: relative; 
  @media (min-width: $screen-md-min) {
    position: fixed; 
    z-index: 499;
  }
}

#navbar {
  nav {
    clear: both;
  }
}

.navbar-default .navbar-nav > li > a {
  color: #000000;
}

.navbar-nav.primary,
.navbar-nav.primary.menu.nav {
  & > li {
    padding: (7px/14px)*1em;
  }
  & > li > .dropdown-menu {
    padding: 0;
  }
  .dropdown-menu > .active > a {
    color: #000;
  }
}

.navbar-nav.secondary,
.navbar-nav.secondary.menu.nav {
  & > li > a {
    padding: (15px/14px)*1em;
    html.force-mobile-layout & {
      padding: (15px/14px)*1em !important;
    }
  }
}

.nav-tabs {
  margin-bottom: 0.72rem;
  border-color: $color-gpii-gray;
  & > li > a:hover {
    border-color: #cccccc #cccccc $color-gpii-gray;
    background-color: $color-gpii-light-gray;
  }
  & > li.active > a,
  & > li.active > a:hover,
  & > li.active > a:focus {
    border-color: $color-gpii-gray;
    border-bottom-color: transparent;
    background-color: $color-gpii-light-gray;
  }
}

.navbar-toggle {
  position: absolute;
  top: 0;
  right: 0;
  &:not(.caret) {
    margin-top: (8px/14px)*1em;
    margin-right: (15px/14px)*1em;
    margin-bottom: (8px/14px)*1em;
    padding: (9px/14px)*1em (10px/14px)*1em;
  }
  &.caret {
    float: none;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding: 0;
    border-top: 0.28571rem solid;
    border-right: 0.28571rem solid transparent;
    border-left: 0.28571rem solid transparent;
    border-radius: 0;
    background: none !important;
  }
  .icon-bar {
    width: (22px/14px)*1em;
    height: (2px/14px)*1em;
  }
  .icon-bar + .icon-bar {
    margin-top: (4px/14px)*1em;
  }
}

.nav {
  li {
    a:hover {
      text-decoration: underline;
    }
  }
}

// #############################################################################
// Fluid UI
// #############################################################################
.flc-iframe {
  width: 100%;
  display:block !important;
}


.fl-prefsEditor-separatedPanel {
  // Increase the specificity here so that defaults are overridden but so that
  // !important isn't required in JavaScript.
  body & {

    height: 0;
    z-index: 10000001;
    position: inherit;
    background: #000;

    @media (min-width: 640px) {
      html:not(.force-mobile-layout) & {
        position: relative;
        top: 2.1rem;
      }
    }

    @media (min-width: 768px) {
      html:not(.force-mobile-layout) & {
        position: fixed;
        width: 100% !important;
        top: 0;
      }
    }
  }
  .fl-panelBar .fl-prefsEditor-buttons {
    height: auto;
    @media (min-width: 640px) {
      box-shadow: 2px 2px 3px 0px #333333;
      border-bottom: 1px solid $color-fluidui-gray;
      border-left: 1px solid $color-fluidui-gray;
      border-right: 1px solid $color-fluidui-gray;
      background-color: #000;
      margin-right: 2%;
    }
    button {
      color: #fff;
      background-color: #000;
      height: 1.8em;
      min-width: 10em;

    }
  }
  .fl-panelBar {
    box-shadow: none !important;
    @media (min-width: 640px) {
      box-shadow:0 2px 3px 0 #6f6f6f;
    }
   }
}

.flc-toc-tocContainer {
  padding-left: 0;
  padding-right: 0;
  padding-top: 2rem;
}

.flc-toc-header,
.flc-toc-levels-level1 {
  margin-right: 15px;
  margin-left: 15px;
}

// keep Fluid from screwing up icon fonts

.fl-font-prefsEditor-comic-sans *:not(.fa):not(.fl-icon-undo) {
  font-family: 'Comic Sans MS', sans-serif !important;
}

.fl-font-prefsEditor-times *:not(.fa):not(.fl-icon-undo) {
  font-family: 'Georgia', 'Times', 'Times New Roman', serif !important;
}

.fl-font-prefsEditor-arial *:not(.fa):not(.fl-icon-undo) {
  font-family: 'Arial', sans-serif !important;
}

.fl-font-prefsEditor-verdana *:not(.fa):not(.fl-icon-undo) {
  font-family: 'Verdana', sans-serif !important;
}




// #############################################################################
// Google Translate
// #############################################################################

// hide translate iframe

body > div.skiptranslate {
  display: none;
}

#block-google-translator-active-languages {
  display: none;
  position: initial;
  @media (min-width: $screen-sm-min) {
    html:not(.force-mobile-layout) & {
      position: fixed;
    }
  }
  top: 0;
  left: 0;
  z-index: 10000002;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid $color-fluidui-gray;
  background-color: $color-fluidui-beige;
  background-repeat: repeat;
  text-align: center;
  .goog-te-gadget {
    margin-right: auto;
    margin-left: auto;
    padding: 1em;
    width: 16em;
    border: 1px solid #615e59;
    border-radius: 5px;
    box-shadow: 2px 2px #7a766d;
    background-color: #fff;
  }
  .google-translator-switch {
    display: none;
  }
  .goog-te-combo,
  .goog-te-gadget,
  .goog-logo-link {
    font-size: 1rem;
    white-space: normal;
  }
  .goog-logo-link img {
    width: auto;
    height: 1rem;
  }
}

.goog-te-banner-frame {
  position: absolute !important;
  margin: 1rem 0 0 !important;
}

#gpii-translate-toggle_container {
  position: initial;
  display: block;
  width: 100%;
   @media (min-width: $screen-xs-min) {
       width: 100%;
    }
  background-color: #000;
  right: 8%;
  z-index: 10000001;
  // min-height: 2em;

  #gpii-translate-toggle_button {
    margin-right: 0.5em;
    margin-left: 1.2rem;
    border: 0;
    padding: 3px 0 2px;
    background-color: #000;
    color: #fff;
    font-family: 'open-sans', 'Myriad Pro', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    text-transform: lowercase;
    @media (min-width: 640px) {
      position:absolute;
      right: 10%;
      width: auto;
      padding: 3px 1rem 2px;
      height: 1.8em;
      box-shadow: 2px 2px 3px 0px #333333;
      border-bottom: 1px solid $color-fluidui-gray;
      border-left: 1px solid $color-fluidui-gray;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 1.8em;
      font-weight: bold;
      font-size: 1.1em;
    }
  }

  // html.force-mobile-layout & {
  //   //float: right;
  //   //margin-right: calc(3rem + 6px);
  // }

  @media (max-width: $screen-xs-max) {
    //float: right;
    margin-right: calc(3rem + 6px);
  }

  @media (min-width: $screen-sm-min) {
    html:not(.force-mobile-layout) & {
      position: fixed;
      top: 0;

    }
  }
}

a:focus {
  outline: 1px dotted $color-fluidui-dark-gray;
  outline-offset: 0px;
}

// Language-specific customizations
body.i18n-el {
  #gpii-translate-toggle_container {
    right: calc(50px + 18rem + 1.5rem);
  }
}

body.i18n-de {
  #gpii-translate-toggle_container {
    width: 15em;
  }
}

// #############################################################################
// Regions and Other Major Sections
// #############################################################################
.navbar.navbar-default {
  margin-bottom: 0;
  border-bottom: none;
  background-color: #ffffff;
  color: #000000;
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .logo {
    margin: 0;
    padding: 0 (15px/14px)*1em (6px/14px)*1em (15px/14px)*1em;
    border-right: 1px solid #ffffff;
    background-color: #fff;
    img {
      display: none;
      @media (min-width: $screen-sm-min) {
        display: block;
        max-width: 100%;
      }
    }
  }
  .navbar-header {
    margin-left: 0;
    @media (min-width: $screen-sm-min) {
      margin-top: -0.9rem;
    }
    margin-bottom: 0.5rem;
    width: 100%;
    position: relative;
    html.force-mobile-layout & {
      margin-left: 0;
    }
    p.lead {
      font-size: 1.6rem;
      padding-top: 1.5rem;
      margin: 0 4.5rem 0 0.5rem;
      @media (min-width: $screen-sm-min) {
        margin: 0 6rem 0 6.5rem;
      }
      font-weight: 600;
      a {
        font-size: 7vw;
        @media (min-width: $screen-xs-min) {
          font-size: 1.8rem;   
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    html:not(.force-mobile-layout) & > .container-fluid:first-child {
      min-height: 3rem;
    }
  }
}

.region-hero {
  background-color: $color-gpii-dark-blue;
  & > * {
    @include primary-column-width;
  }
  &,
  a {
    color: #ffffff;
  }
}

#main-container {
  margin-top: 3rem;
}

.region-sidebar-first,
.region-sidebar-second {
  & >:first-child {
    margin-top: 0;
  }
  @media (min-width: $screen-sm-min) {
    html:not(.force-mobile-layout) & > .block:first-child {
      & > h1,
      & > h2,
      & > h3,
      & > h4,
      & > h5,
      & > h6 {
        &:first-child {
          margin-top: 0;
        }
      }
    }
    html:not(.force-mobile-layout) & > .block:first-child >:first-child header {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0;
      }
    }
  }
}

#block-system-main { 
  a:not(.btn) { 
    text-decoration: underline;
  }
  .field-type-taxonomy-term-reference {
    a {
      text-decoration: none;
      &:hover, &:focus {
        text-decoration: underline; 
      }
    }
  }
} 



#footer {
  border-bottom: 0.85rem solid #2783c6;
  margin: 0;
  clear: both;
  //background-color: #000;
  background: #000 url('../../gpii_base/footer-gradient.png') no-repeat center 0;
  color: #ffffff;
  a {
    color: #ffffff;
    &:hover, &:focus {
      color: #F2EED2;
    }
  }
  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: transparent;
  }
  html:not(.force-mobile-layout) & {
    width: 100%;
    & > * {
      @include primary-column-width;
    }
  }
  h2 {
    margin-top: 0;
  }
  .bean-basic-block {
    .container {
      margin: 0;
    }
  }
}

.gpii-region_row-fine_print {
  margin-top: 2.4rem;
  padding-top: 1.2rem;
  padding-bottom: 4rem;
}


#feedback-wrap {
  background-color: #fff;
  margin-bottom: -4em;
  z-index: 10;
  .contextual-links-region {
    position: unset;
  }

  & > p { // button for feedback forms
    text-align: center;
    margin-bottom: auto;
    .btn {
      border: none;
      white-space: inherit;
      color: $color-gpii-dark-gray;
      margin-bottom: 1rem;
      &:hover, &:focus, &:active {
        outline: dotted 1px $color-gpii-dark-gray;
        border-radius: 4px;
        background-color: transparent;
        font-weight: bold;
        color: #000;
      }

      &.btn.btn-default:before {
        font-family: FontAwesome;
        content: "\f078";
        padding-right: 0.25rem;
        color: #000;
      }

      &.btn.btn-default.collapsed:before {
        content: "\f054";
      }
    }
  }

  #feedback-collapsible {
    margin-bottom: 2.5rem;
  }

  .block-webform {
    padding-bottom: 4rem;
    background-color: $color-gpii-light-gray;

    .container {
      margin-top: 0;
    }
    .webform-component--comments {
      margin-top: 1rem;
    }
    .webform-component--comments, .webform-component-email {
      display: block;
      
    }
    .webform-container-inline label, .webform-container-inline.webform-component-textarea .form-textarea-wrapper, textarea {
      width: 100%;
      display: block;
    }
  }

  @media (min-width: $screen-sm-min) {

    .block-webform form > div:first-child {
      .webform-component--comments, .webform-component-email, .webform-component--email {
        display: grid;
        grid-template-columns: 20% 70%;
        grid-template-rows: auto;
        grid-column-gap: 1rem;
        grid-row-gap: 0.5rem;
        grid-template-areas: 
          "label input"
          "null help"
          "submit submit";
        label {
          grid-column-start: label;
          text-align: right;
        }
        textarea, input {
          grid-column-start: input;
        }
        .help-block, .description {
          grid-column-start: help;
          grid-row-start: help;
          margin-top: 0;
        }
      }
       
      .form-actions { 
        margin-top: 0.5rem;
        text-align: center;
      }
    }
 }
}

// Sticky Footer
html {
  position: relative;
  min-height: 100%;
}

#footer-wrap {
  width: 100%;
  z-index: 3;
}

  #push {
    height: 4rem; // for pages like advanced search, add some extra space to clear
    // the sticky footer
  }

@media (min-width: $screen-sm-min) {
  body {
    /* Margin bottom by footer height */
    margin-bottom: -22rem;
  }

  #footer-wrap {
    position: absolute;
    bottom: 0;
  }

  #push {
    height: 27rem;    // for pages like advanced search, add some extra space to clear
    // the sticky footer
  }
}

//#############################################################################
// Navigation
// #############################################################################
.menu.nav > li > a {
  padding: 2px 0;
}

.navbar-nav {
  margin: 0 0;
}

.navbar-nav.primary.menu.nav {
  background: linear-gradient(to bottom,  $color-gpii-med-gray 0,$color-gpii-med-gray 0.3rem,$color-gpii-gray 0.3rem,$color-gpii-gray 100%);
  width: 100%;
  @media (min-width: $screen-sm-min) {
    ul.dropdown-menu li a {
      &:hover, &:focus, &:active {
        background-color: $color-gpii-gray;
      }
    }

    li {
      padding: 0;
      & > a {
        padding: 0.6rem 1.2em;
        margin: 0;
        font-weight: 600;
        font-size: 1em;
        border-top-width: 0.3rem;
        border-top-style: solid;
        border-top-color: transparent;
        text-decoration: none;
        &:hover, &:focus {
          border-top-color: $color-gpii-blue;
          background-color: #fff;

        }
        &.dropdown > a {
          border-top-color: $color-gpii-med-gray;
          transition: color .3s ease-in-out, border-top-color .3s ease-in-out;

          &:hover {
            border-top-color: $color-gpii-blue;
          }

        }
      }
      ul > li > a {
        border-top-width: 0;
        font-weight: 400;
      }
    }

    .dropdown.active > a,
    .dropdown.active > a:hover,
    .dropdown.active > a:focus,
    .active > a,
    .active > a:hover,
    .active > a:focus {
      background-color: #fff;
      text-decoration: none;
      border-top-color: $color-gpii-blue;
    }

    .dropdown.open > a,
    .dropdown.open > a:hover,
    .dropdown.open > a:focus {
      background-color: #fff;
      color: $color-gpii-blue;
      border-top-color: $color-gpii-blue;
    }
  }

  .active-trail.active > .active-trail.active {
    cursor: initial;
  }
}

.navbar-nav.secondary {
  .navbar-default & > .active > a,
  .navbar-default & > .active > a:hover,
  .navbar-default & > .active > a:focus {
    background-color: transparent;
  }
  body.not-logged-in & {
    & > li.last > a {
      margin: (13px/14px)*1em (11px/14px)*1em (13px/14px)*1em (11px/14px)*1em;
      padding: (2px/14px)*1em (4px/14px)*1em;
      border-radius: 2px;
      color: #ffffff;
      font-size: 0.96rem;
      font-weight: bold;
      &,  
      &:hover {
        background-color: darken($color-gpii-blue, 15%);
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    html:not(.force-mobile-layout) & {
      position: absolute;
      top: 3rem;
      right: 15px;
    }
  }
}

// #############################################################################
// Blocks
// #############################################################################
.block-title {
  word-break: normal;
}

.block-menu-block {
  #footer & {
    .block-title {
      font-size: 1.6rem;
    }
    .nav > li > a {
      padding: 0;
    }
  }
}

// #############################################################################
// Miscellaneous
// #############################################################################
.page-header {
  margin-top: 0;
  border-bottom: none;
}


// Override some issues with responsiveness off EU Cookie Compliance module CSS
#sliding-popup {
  .popup-content {
    max-width: 1200px;
    padding: 0 1rem;
    #popup-text {
      margin-top: 1rem;
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1.75rem;
      }
      h3 {
        font-size: 1.5rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }

}


.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #464646;
}


// This is redundant with what honeypot is already supposed to be doing. For some reason, the UL home page wasn't getting this CSS (anonymous users only, so adding duplicate here as workaround)
.form-item-homemail {
  display: none !important;
}

// Override bootstrap issues with fieldset legends extending beyond their bounding box
.panel-default {
  & > .panel-heading {
    float: left;
    width: 100%;
    font-size: 1.2rem;
    padding: 8px 12px;
    .panel-title {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}


